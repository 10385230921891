<template>
  <b-card title="Pribadi">
    <b-card-text>This is your Pribadi page.</b-card-text>
    <b-card-text>test content.</b-card-text>
  </b-card>
</template>

<script>
import { BCard, BCardText } from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardText,
  },
}
</script>

<style>

</style>
